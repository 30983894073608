import { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Nav from './Nav';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';
import BuyNow from './BuyNow';
import Modal from './Modal';
import { useTranslation } from 'react-i18next';

const HubDetail = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const gifLink = location.state?.gifLink;
  const price = location.state?.price;
  const producedBy = location.state?.producedBy;

  const name = location.state?.name;
  const description = location.state?.description;

  const [iframeSize, setIframeSize] = useState('desktop');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleIframeSizeChange = (deviceType) => {
    setIframeSize(deviceType);
  };

  return (
    <>
      <Nav />
      <div className='container-fluid py-6 mt-4'>
        <div className='ml-3 py-4'>
          {/* <div
            className='text-center pb-5 wow fadeIn'
            data-wow-delay='.3s'
            style={{ maxWidth: '600px' }}
          >
            <h5 className='txt-primary'>Contact Us</h5>
            <h1 className='mb-3'>We are here to help you</h1>
          </div> */}

          <div className=''>
            <div className='row g-2'>
              <div
                className='col-lg-9 wow fadeIn'
                data-wow-delay='.3s'
                style={{ height: '680px' }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '640px',
                  }}
                >
                  <div
                    style={{
                      width: iframeSize === 'desktop' ? '100%' : '375px',
                      height: iframeSize === 'desktop' ? '600px' : '600px',
                      border:
                        iframeSize === 'desktop'
                          ? '10px solid #000'
                          : '7px solid #000',
                      boxShadow:
                        iframeSize === 'desktop'
                          ? '0px 0px 10px 0px rgba(0,0,0,0.1)'
                          : '0px 0px 7px 0px rgba(0,0,0,0.1)',
                      boxSizing:
                        iframeSize === 'desktop' ? 'border-box' : 'content-box',
                      borderRadius: iframeSize === 'desktop' ? '15px' : '15px',
                    }}
                  >
                    <embed
                      title='Website Preview'
                      src={gifLink}
                      className='w-100'
                      style={{
                        border: 'none',
                        height: '560px',
                        width: iframeSize === 'desktop' ? '100%' : '375px',
                        maxWidth: iframeSize === 'desktop' ? '100%' : '375px',
                        margin: '0 auto',
                        display: 'block',
                        borderRadius: '10px',
                      }}
                      loading='lazy'
                    />
                  </div>
                </div>

                <div className='d-flex justify-content-center my-5 mt-1 wow fadeIn gap-2'>
                  <button
                    className={`btn btn-outline hover-primary ${
                      iframeSize === 'desktop' ? 'active' : ''
                    }`}
                    style={{
                      border: '1px solid #0078d7',
                      transition: 'all 0.3s',
                    }}
                    onClick={() => handleIframeSizeChange('desktop')}
                  >
                    <i className='fas fa-desktop'></i>
                  </button>
                  <button
                    className={`btn btn-outline hover-primary ${
                      iframeSize === 'mobile' ? 'active' : ''
                    }`}
                    style={{
                      border: '1px solid #0078d7',
                      transition: 'all 0.3s',
                    }}
                    onClick={() => handleIframeSizeChange('mobile')}
                  >
                    <i className='fas fa-mobile-alt'></i>
                  </button>
                </div>
              </div>

              <div className='col-lg-3 justify-content-center '>
                <div className='text-center' style={{ marginTop: '50px' }}>
                  <h3 className='txt-primary'> {name} </h3>
                  <p className='mt-3 mx-4 text-sm text-wrap'>{description}</p>
                  <div className='d-flex gap-2 justify-content-center'>
                    <button
                      className='btn btn-outline hover-primary'
                      style={{
                        border: '1px solid #0078d7',
                      }}
                    >
                      <a
                        href={gifLink}
                        target='_blank'
                        rel='noreferrer'
                        style={{
                          color: 'inherit',
                        }}
                      >
                        {t(`Live Preview`, {
                          ns: 'components_header_trn',
                        })}
                      </a>
                    </button>
                    <button
                      className='btn btn-outline hover-primary'
                      style={{
                        border: '1px solid #0078d7',
                        transition: 'all 0.3s',
                      }}
                      onClick={openModal}
                    >
                      {/* <i className='fas fa-shopping-cart mt-2'></i>  */}
                      {t(`Buy Now`, { ns: 'components_header_trn' })}
                    </button>
                  </div>

                  <h4 className='mt-5 mb-3'>
                    {t(`Theme Information`, { ns: 'components_header_trn' })}
                  </h4>
                  <div
                    className='text-start ml-5'
                    style={{ marginLeft: '30px' }}
                  >
                    <div className=''>
                      <p>Price : ETB {price}</p>
                      <div className='d-flex gap-3 '>
                        <p>Type : </p>

                        <i className='fab fa-vuejs ml-3' title='Vue.js'></i>
                        <i
                          className='fab fa-react ml-3'
                          title='React/Next.js'
                        ></i>
                        <i className='fas fa-wind' title='Tailwind CSS'></i>
                      </div>
                      <p>Created by : {producedBy} </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=''>
            <div className='text-center pb-3 wow fadeIn'>
              <h3 className='txt-primary'>
                {t(`Key Features`, { ns: 'components_header_trn' })}
              </h3>
              <p className='text-primary'>
                {t(`here are key features of`, {
                  ns: 'components_header_trn',
                })}
                {'    '}
                {name}
              </p>
            </div>
            <div className='d-flex gap-3 justify-content-center'>
              <div>
                <ul>
                  <li>
                    Highly customizable (Color, Font, Menu, Social Links, SEO
                    Meta Tags, etc.)
                  </li>
                  <li>Built with Tailwind CSS framework</li>
                  <li>Contact form support</li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>
                    Fully responsive on desktops, tablets, and smartphones
                  </li>
                  <li>SEO Friendly</li>
                  <li>Simple and easy development environment setup</li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>Compatible with all modern browsers</li>
                  <li>Free Google fonts</li>
                </ul>
              </div>
            </div>
          </div>

          <div
            className='row g-5 mb-5 justify-content-center my-5'
            style={{ marginLeft: '10px', marginRight: '10px' }}
          >
            <div className='col-xl-4 col-lg-6 wow fadeIn' data-wow-delay='.3s'>
              <div className='d-flex bg-light p-3 rounded'>
                <div
                  className='flex-shrink-0 btn-square bg-secondary rounded-circle'
                  style={{ width: '64px', height: '64px' }}
                >
                  <i className='fas fa-map-marker-alt text-white'></i>
                </div>
                <div className='ms-3'>
                  <h4 className='txt-primary'>
                    {t(`Address`, { ns: 'components_header_trn' })}
                  </h4>
                  <a
                    href='https://maps.app.goo.gl/pucefZmSJgThUMte8'
                    target='_blank'
                    className='h5'
                  >
                    {t(` Gurd Shola, Addis Ababa, Ethiopia`, {
                      ns: 'components_header_trn',
                    })}
                  </a>
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-lg-6 wow fadeIn' data-wow-delay='.5s'>
              <div className='d-flex bg-light p-3 rounded'>
                <div
                  className='flex-shrink-0 btn-square bg-secondary rounded-circle'
                  style={{ width: '64px', height: '64px' }}
                >
                  <i className='fa fa-phone text-white'></i>
                </div>
                <div className='ms-3'>
                  <h4 className='txt-primary'>
                    {t(`Call Us`, { ns: 'components_header_trn' })}
                  </h4>
                  <a className='h5' href='tel:+251911114048' target='_blank'>
                    +2519 1111 4048
                  </a>
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-lg-6 wow fadeIn' data-wow-delay='.7s'>
              <div className='d-flex bg-light p-3 rounded'>
                <div
                  className='flex-shrink-0 btn-square bg-secondary rounded-circle'
                  style={{ width: '64px', height: '64px' }}
                >
                  <i className='fa fa-envelope text-white'></i>
                </div>
                <div className='ms-3'>
                  <h4 className='txt-primary'>
                    {t(`Email Us`, { ns: 'components_header_trn' })}
                  </h4>
                  <a
                    className='h5'
                    href='mailto:info@kachmale.com'
                    target='_blank'
                  >
                    info@kachamale.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={
            <p className='txt-primary text-center'>
              {' '}
              {t(`Buy Now`, { ns: 'components_header_trn' })} {name}
            </p>
          }
          style={{ maxWidth: '800px', width: '50%', borderRadius: '20px' }}
        >
          <BuyNow />
        </Modal>
      </div>
      <Footer />
    </>
  );
};

export default HubDetail;

{
  /* <div
  className='text-center wow fadeIn justify-content-center'
  style={{ height: '100px' }}
>
  <h4 className='txt-primary text-center italic text-sm'>$5</h4>
  <div className='d-flex mt-3 justify-content-center'>
    {5 && (
      <>
        {Array(5)
          .fill()
          .map((_, i) => (
            <i
              key={i}
              className={`fas fa-star me-1 ${i < 5 ? 'text-warning' : ''}`}
            ></i>
          ))}

        <p className='ms-2 ml-2'> (10) </p>
      </>
    )}
  </div>
  <p>Last Update 8/1/2024</p>
  <div className='d-flex gap-2 justify-content-center'>
    <button
      className='btn btn-outline hover-primary'
      style={{
        border: '1px solid #0078d7',
        transition: 'all 0.3s',
      }}
    >
      <i className='fas fa-shopping-cart mt-2'>
        <a href='/#'></a>{' '}
      </i>
    </button>

    <button
      className='btn btn-outline hover-primary'
      style={{
        border: '1px solid #0078d7',
      }}
    >
      <a
        href={gifLink}
        target='_blank'
        rel='noreferrer'
        style={{
          color: 'inherit',
        }}
      >
        Live Preview
      </a>
    </button>
  </div>
</div>*/
}
