import { useTranslation } from 'react-i18next';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';
import Nav from '../components/Nav';

const Contact = () => {
  const { t } = useTranslation();
  return (
    <>
      <Nav />
      {/* <!-- Page Header Start --> */}
      <div className='container-fluid page-header py-5'>
        <div className='container text-center py-5'>
          <h1 className='display-2 text-white mb-4 animated slideInDown'>
            {t(`Contact Us`, {
              ns: 'components_header_trn',
            })}
          </h1>
          <nav aria-label='breadcrumb animated slideInDown'>
            <ol className='breadcrumb justify-content-center mb-0'>
              <li className='breadcrumb-item'>
                <a href='#'>{t(`Home`)}</a>
              </li>
              <li className='breadcrumb-item'>
                <a href='#'>
                  {t(`Pages`, {
                    ns: 'components_header_trn',
                  })}
                </a>
              </li>
              <li className='breadcrumb-item' aria-current='page'>
                {t(`Contact`)}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* <!-- Page Header End --> */}

      {/* <Stat/> */}

      {/* <!-- Contact Start --> */}
      <ContactForm />
      {/* <!-- Contact End --> */}
      <Footer />
    </>
  );
};

export default Contact;
