import { useTranslation } from 'react-i18next';
import useAxiosQuery from '../baseQueryHook/useAxiosBaseQuery';

const Footer = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const collection = {
    locale: currentLanguage,
  };

  const { data: socialsMedia } = useAxiosQuery({
    url: '/portal-interactions/get-social-medias',
    method: 'GET',
    params: collection,
  });

  return (
    <div
      className='container-fluid footer bg-dark2 wow fadeIn'
      data-wow-delay='.3s'
    >
      <div className='container pt-5 pb-4'>
        <div className='row g-5'>
          <div className='col-lg-3 col-md-6'>
            <a href='/'>
              <h1 className='text-white fw-bold d-block'>
                {t(`Kachamale`, { ns: 'components_header_trn' })}
                <span className='txt-primary'>
                  {' '}
                  {t(`Technology`, { ns: 'components_header_trn' })}
                </span>
              </h1>
            </a>
            <p className='mt-4 text-light'>
              {t(
                `We are a team driven by a passion for technology and a desire to make a difference in the world.`,
                { ns: 'components_header_trn' }
              )}
            </p>
            <div className='d-flex footer-link'>
              {socialsMedia &&
                socialsMedia?.data?.map((social) => (
                  <a
                    href={social.link}
                    target='_blank'
                    className='btn-light nav-fill btn btn-square rounded-circle me-2'
                    data-bs-toggle='tooltip'
                    title={social.title}
                    key={social.id}
                  >
                    {social.name === 'facebook' ||
                    social.name === 'Facebook' ? (
                      <i className='fab fa-facebook-f txt-primary'></i>
                    ) : social.name === 'twitter' ||
                      social.name === 'Twitter' ? (
                      <i className='fab fa-twitter txt-primary'></i>
                    ) : social.name === 'instagram' ||
                      social.name === 'Instagram' ? (
                      <i className='fab fa-instagram txt-primary'></i>
                    ) : social.name === 'linkedin' ||
                      social.name === 'Linkedin' ? (
                      <i className='fab fa-linkedin-in txt-primary'></i>
                    ) : social.name === 'telegram' ||
                      social.name === 'Telegram' ? (
                      <i className='fab fa-telegram txt-primary'></i>
                    ) : social.name === 'youtube' ||
                      social.name === 'Youtube' ? (
                      <i className='fab fa-youtube txt-primary'></i>
                    ) : social.name === 'github' || social.name === 'Github' ? (
                      <i className='fab fa-github txt-primary'></i>
                    ) : social.name === 'discord' ||
                      social.name === 'Discord' ? (
                      <i className='fab fa-discord txt-primary'></i>
                    ) : social.name === 'whatsapp' ||
                      social.name === 'Whatsapp' ? (
                      <i className='fab fa-whatsapp txt-primary'></i>
                    ) : social.name === 'tiktok' || social.name === 'Tiktok' ? (
                      <i className='fab fa-tiktok txt-primary'></i>
                    ) : social.name === 'pinterest' ||
                      social.name === 'Pinterest' ? (
                      <i className='fab fa-pinterest txt-primary'></i>
                    ) : social.name === 'reddit' || social.name === 'Reddit' ? (
                      <i className='fab fa-reddit txt-primary'></i>
                    ) : social.name === 'snapchat' ||
                      social.name === 'Snapchat' ? (
                      <i className='fab fa-snapchat txt-primary'></i>
                    ) : (
                      <i className='fab fa-google txt-primary'></i>
                    )}
                  </a>
                ))}
            </div>
          </div>
          <div className='col-lg-3 col-md-6'>
            <a href='#' className='h3 txt-primary'>
              {t(`Short Link`, { ns: 'components_header_trn' })}
            </a>
            <div className='mt-4 d-flex flex-column short-link'>
              <a href='/about' className='mb-2 text-white'>
                <i className='fas fa-angle-right txt-primary me-2'></i>
                {t(`About Us`, { ns: 'components_header_trn' })}
              </a>
              <a href='/contact' className='mb-2 text-white'>
                <i className='fas fa-angle-right txt-primary me-2'></i>
                {t(`Contact Us`, { ns: 'components_header_trn' })}
              </a>
              <a href='/services' className='mb-2 text-white'>
                <i className='fas fa-angle-right txt-primary me-2'></i>
                {t(`Our Services`, { ns: 'components_header_trn' })}
              </a>
              <a href='/projects' className='mb-2 text-white'>
                <i className='fas fa-angle-right txt-primary me-2'></i>
                {t(`Our Project`, { ns: 'components_header_trn' })}
              </a>
            </div>
          </div>
          <div className='col-lg-3 col-md-6'>
            <a href='#' className='h3 txt-primary'>
              {t(`Products`, { ns: 'components_header_trn' })}
            </a>
            <div className='mt-4 d-flex flex-column help-link'>
              {/* <a
                href="https://web.yenechinet.com/"
                target="_blank"
                className="mb-2 text-white"
              >
                <i className="fas fa-angle-right txt-primary me-2"></i>
                Yene Chinet
              </a> */}
              <a href='#' className='mb-2 text-white'>
                <i className='fas fa-angle-right txt-primary me-2'></i>
                {t(`Property Management (PMS)`, {
                  ns: 'components_header_trn',
                })}
              </a>
              <a
                href='http://primepropertiesandlogistics.com/'
                target='_blank'
                className='mb-2 text-white'
              >
                <i className='fas fa-angle-right txt-primary me-2'></i>
                {t(`Prime Property & Logistics Solution`, {
                  ns: 'components_header_trn',
                })}
              </a>
            </div>
          </div>
          <div className='col-lg-3 col-md-6'>
            <a href='#' className='h3 txt-primary'>
              {t(`Contact Us`, { ns: 'components_header_trn' })}
            </a>
            <div className='text-white mt-4 d-flex flex-column contact-link'>
              <a href='#' className=' text-light'>
                <i className='fas fa-map-marker-alt txt-primary me-2'> </i>
                {t(`Gurd Shola, Addis Ababa, Ethiopia`, {
                  ns: 'components_header_trn',
                })}
              </a>
              <a href='#' className='py-1 text-light'>
                <i className='fas fa-phone-alt txt-primary me-2'></i>{' '}
                {t(`Support:`, { ns: 'components_header_trn' })} +251911058962 |
                +251911049517 | +251911051598
              </a>

              <a href='#' className='py-1 text-light'>
                <i className='fas fa-phone-alt txt-primary me-2'></i>
                {t(`Sales & Marketing:`, { ns: 'components_header_trn' })}{' '}
                +251911052499 | +251911054804
              </a>
              <a href='#' className='py-1 text-light'>
                <i className='fas fa-envelope txt-primary me-2'></i>{' '}
                info@kachamale.con
              </a>
            </div>
          </div>
        </div>
        <hr className='text-light mt-5 mb-4' />
        <div className='row'>
          <div className='col-md-6 text-center text-md-start'>
            <span className='text-light'>
              <a href='#' className='txt-primary'>
                <i className='fas fa-copyright txt-primary me-2'></i>
                {t(`Kachamale Technologies, All right reserved.`, {
                  ns: 'components_header_trn',
                })}
              </a>
            </span>
          </div>
          <div className='col-md-6 text-center text-md-end'>
            <span className='text-light'>
              {t(`Powered By Kachamale Development Team`, {
                ns: 'components_header_trn',
              })}
              {/* Powered By
              <a href='https://kachmale.com' className='txt-primary'>
                Kachamale Development Team
              </a> */}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
