import Slider from 'react-slick/lib/slider';
import useAxiosQuery from '../baseQueryHook/useAxiosBaseQuery';
import { useTranslation } from 'react-i18next';

const OurClients = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const collection = {
    locale: currentLanguage,
  };

  const { data: clientData } = useAxiosQuery({
    url: '/portal-customer/get-clients',
    method: 'GET',
    params: collection,
  });

  const settings = {
    // dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className='container-fluid services py-4 mb-4'>
        <div className='container'>
          <div
            className='text-center mx-auto pb-4 wow fadeIn'
            data-wow-delay='.3s'
            style={{ maxWidth: '600px' }}
          >
            <h5 className='txt-primary'>
              {t(`Our Clients`, { ns: 'components_header_trn' })}
            </h5>
            <h1>
              {t(`We have delivered and trusted by multiple clients`, {
                ns: 'components_header_trn',
              })}
            </h1>
          </div>
          <div className='row g-5 services-inner'>
            <div className='slider-container'>
              <Slider {...settings}>
                {clientData &&
                  clientData?.data?.map((client) => (
                    <div
                      className='col-lg-4 col-md-6 wow fadeInUp'
                      data-wow-delay='.1s'
                      key={client.id}
                    >
                      <img
                        title={client.name}
                        className='client-img'
                        src={client.logo ? client.logo : '/img/defalt logo.png'}
                        alt={client.name}
                      />
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurClients;
