const UpArrow = () => {
  return (
    <>
      <a
        href='#'
        className='btn btn-primary btn-square rounded-circle back-to-top'
      >
        <i className='fa fa-arrow-up text-white'></i>
      </a>
    </>
  );
};

export default UpArrow;
