import Nav from '../components/Nav';
import HeroSlide from '../components/HeroSlide';
import Service from './Service';
import Project from './Project';
import WhyUs from '../components/whyUs';
import OurClients from '../components/Clients';
import Testimonial from '../components/Testimonial';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';
import UpArrow from '../components/UpArrow';
import { Suspense } from 'react';
import OurPartner from '../components/partner';

function Home() {
  return (
    <Suspense>
      <Nav />
      <Suspense
        fallback={
          <div
            style={{
              height: '300px',
            }}
          >
            <span style={{ color: 'red', fontSize: '20rem' }}>loading...</span>
          </div>
        }
      >
        <HeroSlide />
      </Suspense>
      <Service />
      <Project />
      <WhyUs />
      <OurClients />
      <Testimonial />
      <OurPartner />
      {/* <Hub /> */}
      <ContactForm />
      <Footer />
      <UpArrow />
    </Suspense>
  );
}

export default Home;
