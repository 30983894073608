import { useTranslation } from 'react-i18next';
import Footer from '../components/Footer';
import Nav from '../components/Nav';

const About = () => {
  const { t } = useTranslation();
  return (
    <>
      <Nav />
      {/* <!-- Page Header Start --> */}
      <div className='container-fluid page-header py-5'>
        <div className='container text-center py-5'>
          <h1 className='display-2 text-white mb-4 animated slideInDown'>
            {t(`About Us`, {
              ns: 'components_header_trn',
            })}
          </h1>
          <nav aria-label='breadcrumb animated slideInDown'>
            <ol className='breadcrumb justify-content-center mb-0'>
              <li className='breadcrumb-item'>
                <a href='#'>{t(`Home`)}</a>
              </li>
              <li className='breadcrumb-item'>
                <a href='#'>
                  {t(`Pages`, {
                    ns: 'components_header_trn',
                  })}
                </a>
              </li>
              <li className='breadcrumb-item' aria-current='page'>
                {t(`About`)}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* <Stat /> */}

      {/* <!-- About Start --> */}
      <div className='container-fluid py-2 my-5'>
        <div className='container py-2'>
          <div className='row'>
            <div
              className='col-lg-5 col-md-6 col-sm-12 wow fadeIn'
              data-wow-delay='.3s'
            >
              <div className='h-100 position-relative'>
                <img
                  src='/img/log-vertical@3x.png'
                  className='img-fluid w-75 rounded'
                  alt=''
                  style={{ marginBottom: '25%' }}
                />
              </div>
            </div>
            <div
              className='col-lg-7 col-md-6 col-sm-12 wow fadeIn'
              data-wow-delay='.5s'
            >
              <h5 className='txt-primary'>
                {t(`About Us`, { ns: 'components_header_trn' })}
              </h5>
              <h2 className='mb-4'>
                {t(
                  `About Kachmale Software Solutions And Its Innovative IT Solutions`,
                  { ns: 'about_des_trn' }
                )}
              </h2>
              <p>
                {t(
                  `We are a technology company established in 2023 based in Addis Ababa, Ethiopia. Kachamale concentrates in developing innovative and user-friendly technology solutions. We believe that technology can be used to solve real-world problems and improve people's lives. Our mission is to build softwares that is powerful, useful, user friendly, easy to use, affordable, and Ethical.`,
                  { ns: 'about_des_trn' }
                )}
              </p>
              <p className='mb-4'>
                {t(
                  'Kachamale Software Solutions is a brand that embodies innovation, reliability, and expertise in providing cutting-edge software solutions. The brand essence is to empower businesses with transformative technology solutions that drive growth and success.',
                  { ns: 'about_des_trn' }
                )}
              </p>
            </div>
          </div>
          <div className='row g-5'>
            <div className='col-sm-6'>
              <p>
                {t(
                  'We want our products to be accessible to organizations and end users. We also believe that software should be sustainable and ethical. We are committed to creating products that have a positive impact on society. We are a team of people who are passionate about technology and making a difference in the world. Our company gives professional service and cost effective solutions without any quality compromisation.',
                  { ns: 'about_des_trn' }
                )}
              </p>
              <p>
                {t(
                  `The Kachamale Software Solutions brand is not simply our logo or name, but it is one of our primary tools for expressing our business strategy to all audiences. The brand reflects the experiences that people have with Kachamale Software Solutions, our products and services, as well as our employees. Perceptions of the brand are built each time a customer interacts with Kachamale Software Solutions through channels such as advertising and sales. Brand perceptions are also built when a customer uses our technology, during a customer service experience, or during a provisioning interaction.`,
                  { ns: 'about_des_trn' }
                )}
              </p>
              <p>
                {t(
                  `Our marketing communications play an important role in shaping and reinforcing the desired view of Kachamale Software Solutions with our customers. Therefore, it is critical that all marketing efforts express the intended brand messages and imagery. Speaking with one consistent voice to all audiences, internal and external, will build a stronger and better positioned Kachamale Software Solutions for the future.`,
                  { ns: 'about_des_trn' }
                )}
              </p>
            </div>
            <div className='col-sm-6'>
              <h4 className='txt-primary'>
                {t(`Our Vision`, { ns: 'components_header_trn' })}
              </h4>
              <p>
                {t(
                  `In three years, Kachamale Software Solutions envisions itself as the go-to platform for software solutions in Ethiopia and Africa. We aim to be recognized as a leader in the industry, known for our expertise, innovation, and commitment to delivering tailored solutions that address the specific needs of each client.`,
                  { ns: 'about_des_trn' }
                )}
              </p>
              <h4 className='txt-primary'>
                {t(`Our Mission`, { ns: 'components_header_trn' })}
              </h4>
              <p>
                {t(
                  `Our mission is to build softwares that is powerful, useful, user friendly, easy to use, affordable, and Ethical. Kachamale Software Solutions is on a mission to provide comprehensive software development services and platforms that enable businesses in Ethiopia and Africa to thrive. We are dedicated to harnessing the power of technology, fostering innovation, and delivering customized solutions that drive tangible results and fuel business growth.`,
                  { ns: 'about_des_trn' }
                )}
                {/* Our mission is to build softwares that is powerful, useful, user
                friendly, easy to use, affordable, and Ethical. <br />
                Kachamale Software Solutions is on a mission to provide
                comprehensive software development services and platforms that
                enable businesses in Ethiopia and Africa to thrive. We are
                dedicated to harnessing the power of technology, fostering
                innovation, and delivering customized solutions that drive
                tangible results and fuel business growth. */}
              </p>
            </div>
          </div>

          <div className='row g-5 mb-0'>
            <h4 className='txt-primary' style={{ textAlign: 'center' }}>
              {t(`Our Goals`, { ns: 'components_header_trn' })}
            </h4>

            <div className='col-sm-6'>
              <p>
                {t(
                  `The goal of Kachamale Software Solutions is to revolutionize industry by providing cost-effective and efficient software solutions that empower businesses and drive excellence in their operations. The company aims to:`,
                  { ns: 'about_des_trn' }
                )}
              </p>
              <ul>
                <li>
                  <strong>
                    {t(`Marketplace Establishment:`, { ns: 'about_des_trn' })}{' '}
                  </strong>
                  {t(
                    `Kachamale Software Solutions aims to establish itself as a prominent marketplace for software development in Ethiopia and Africa. We strive to connect businesses and organizations with top-notch developers, platforms, and solutions that align with their unique requirements.`,
                    { ns: 'about_des_trn' }
                  )}
                </li>
                <li>
                  <strong>
                    {t(`Industry Leadership:`, { ns: 'about_des_trn' })}
                  </strong>
                  {t(
                    `We aspire to be a leader in the software development industry, setting the standard for expertise, innovation, and customer satisfaction. By consistently delivering high-quality solutions, we aim to earn the trust and recognition of clients and industry peers alike.`,
                    {
                      ns: 'about_des_trn',
                    }
                  )}
                </li>
                <li>
                  <strong>
                    {t(`Customized Solutions:`, { ns: 'about_des_trn' })}
                  </strong>
                  {t(
                    `Kachamale Software Solutions is committed to providing tailored software solutions that address the specific needs of each client. Our goal is to understand our clients challenges and objectives, and deliver innovative and customized solutions that drive their success.`,
                    { ns: 'about_des_trn' }
                  )}
                </li>
                <li>
                  <strong>
                    {t(`Market Expansion:`, { ns: 'about_des_trn' })}
                  </strong>
                  {t(
                    `Over the next three years, we aim to expand our reach and impact across Ethiopia and Africa. We strive to collaborate with businesses and organizations in various sectors, offering our expertise and solutions to help them achieve their goals and contribute to their growth.`,
                    { ns: 'about_des_trn' }
                  )}
                </li>
              </ul>
            </div>
            <div className='col-sm-6'>
              <ul>
                <li>
                  <strong>
                    {t(`Technological Innovation:`, { ns: 'about_des_trn' })}
                  </strong>{' '}
                  {t(
                    `Kachamale Software Solutions places a strong emphasis on technological innovation. We aim to continuously explore and adopt emerging technologies, tools, and frameworks to stay ahead of the curve and provide our clients with cutting-edge solutions.`,
                    { ns: 'about_des_trn' }
                  )}
                </li>
                <li>
                  <strong>
                    {t(`Customer Satisfaction:`, { ns: 'about_des_trn' })}
                  </strong>{' '}
                  {t(
                    `Kachamale Software Solutions aims to achieve high levels of customer satisfaction by delivering top-quality software solutions that exceed client expectations. We strive to build long-term relationships with clients based on trust, reliability, and exceptional service.`,
                    { ns: 'about_des_trn' }
                  )}
                </li>
                <li>
                  <strong>
                    {t(`Collaboration:`, { ns: 'about_des_trn' })}
                  </strong>{' '}
                  {t(
                    `The company aims to create a collaborative ecosystem within the various industries. By developing software solutions that facilitate seamless communication, coordination, and information sharing among stakeholders, Kachamale aims to promote collaboration and synergy, leading to improved overall performance`,
                    { ns: 'about_des_trn' }
                  )}
                </li>
                <li>
                  <strong>
                    {t(`Enhance Efficiency:`, { ns: 'about_des_trn' })}
                  </strong>{' '}
                  {t(
                    `Kachamale Software Solutions strives to optimize through innovative software solutions. By automating manual tasks, improving supply chain visibility, and enhancing data-driven decision-making, the goal is to help businesses operate more efficiently and reduce costs.`,
                    { ns: 'about_des_trn' }
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- About End --> */}

      <Footer />
    </>
  );
};

export default About;
