import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import React, { Suspense } from 'react';
import About from './pages/About.jsx';
import Home from './pages/Home.jsx';
import Contact from './pages/Contact.jsx';
import Service from './pages/Service.jsx';
import Project from './pages/Project.jsx';
import ProductDetail from './pages/ProductDetail.jsx';
import NotFoundPage from './pages/NotFoundPage.jsx';
import Hub from './pages/hub.jsx';
import HubDetail from './components/HubDetail.jsx';
import './i18n.js';

// import { SWRConfig } from 'swr';
// import fetcher from './baseQuery/fetcher.js';
{
  /* <Route path='/' element={<p className='text-blue-700'>Hello, world</p>}></Route> */
}
const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/about',
    element: <About />,
  },
  {
    path: '/contact',
    element: <Contact />,
  },
  {
    path: '/services',
    element: <Service />,
  },
  {
    path: '/projects',
    element: <Project />,
  },
  {
    path: '/portfolio',
    element: <Hub />,
  },
  {
    path: '/projects/:id',
    element: <ProductDetail />,
  },
  {
    path: '/hub/:id',
    element: <HubDetail />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]);
ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    {/* <App /> */}
    {/* <SWRConfig value={fetcher}> */}
    <Suspense fallback={<div>Loading...</div>}>
      <RouterProvider router={router} />
    </Suspense>
    {/* </SWRConfig> */}
    {/* <App /> */}
  </React.StrictMode>
);
