import axios from 'axios';
import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BuyNow = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });

  const notify = () => toast('Message sent successfully!');

  const handleSubmit = async (event) => {
    event.preventDefault();
    axios
      .post(
        'https://saas_web.yenechinet.com/api/portal-interactions/create-contact-us',
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'CO1tfl8xcSbtmQD3054dc1a-301c-45ff-90eb-900b5e67ecc3',
          },
        }
      )
      .then((response) => {
        console.log(response);
        setFormData({
          name: '',
          email: '',
          phone: '',
          subject: '',
          message: '',
        });
        notify();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className='mx-16 wow fadeIn' data-wow-delay='.5s'>
        <form onSubmit={handleSubmit}>
          <div className='rounded contact-form'>
            <div className='mb-2'>
              <input
                type='text'
                className='form-control py-3'
                placeholder='Your Name'
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
              />
            </div>
            <div className='mb-2'>
              <input
                type='phone'
                className='form-control border-1 py-3'
                placeholder='Phone Number'
                value={formData.phone}
                onChange={(e) =>
                  setFormData({ ...formData, phone: e.target.value })
                }
              />
            </div>
            <div className='mb-2'>
              <input
                type='email'
                className='form-control border-1 py-3'
                placeholder='Email Address'
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
            </div>
            <div className='mb-2'>
              <input
                type='text'
                className='form-control border-1 py-3'
                placeholder='Subject'
                value={formData.subject}
                onChange={(e) =>
                  setFormData({ ...formData, subject: e.target.value })
                }
              />
            </div>
            <div className='mb-2'>
              <textarea
                className='w-100 form-control border-1 py-3'
                rows={3}
                cols={7}
                placeholder='Message'
                value={formData.message}
                onChange={(e) =>
                  setFormData({ ...formData, message: e.target.value })
                }
              ></textarea>
            </div>
            <div className='text-end'>
              <button
                className='btn bg-primary text-white py-2 px-3'
                type='submit'
              >
                Send Message
              </button>
            </div>
          </div>
        </form>
        <ToastContainer position='bottom-right' style={{ color: 'white' }} />
      </div>
    </>
  );
};
export default BuyNow;
