import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    i18n.language || 'en'
  );

  const LANGUAGES = [
    { label: 'English', lang: 'en', icon: '/lang_flags/usa_flag.svg' },
    { label: 'Amharic', lang: 'am', icon: '/lang_flags/am_flag.svg' },
    { label: 'Oromo', lang: 'om', icon: '/lang_flags/om_flag.svg' },
    { label: 'Tigrinya', lang: 'ti', icon: '/lang_flags/ti_flag.svg' },
    { label: 'Somali', lang: 'so', icon: '/lang_flags/so_flag.svg' },
  ];

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleLanguageChange = (lang) => {
    setSelectedLanguage(lang);
    i18n.changeLanguage(lang);
    setIsOpen(false);
  };

  const selectedLang = LANGUAGES.find((lang) => lang.lang === selectedLanguage);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className='dropdown'>
      <button
        onClick={toggleDropdown}
        className='btn dropdown-toggle  d-flex align-items-center'
        type='button'
        aria-expanded={isOpen}
        style={{
          backgroundColor: 'primary',
          color: 'white',
          marginRight: isMobile ? '10px' : '30px',
        }}
        title='Language'
      >
        <img
          src={selectedLang?.icon}
          alt={selectedLang?.label}
          style={{ width: '24px', marginRight: '0px' }}
        />
      </button>
      {isOpen && (
        <ul
          className='dropdown-menu show'
          style={{
            marginTop: '5px',
            paddingRight: '1500px',
            borderRadius: '10px',
            padding: '5px 0',
            backgroundColor: '#fff',
          }}
        >
          {LANGUAGES.map((lang) => (
            <li
              key={lang.lang}
              onClick={() => handleLanguageChange(lang.lang)}
              style={{
                marginRight: isMobile ? '25px' : '10px',
                marginLeft: '5px',
              }}
            >
              <button
                className='dropdown-item d-flex align-items-center'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: '5px',
                }}
              >
                <img
                  src={lang.icon}
                  alt={lang.label}
                  style={{ width: '20px', marginRight: '10px' }}
                />
                {lang.label}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageSwitcher;
