import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  ResponseType,
} from 'axios';

interface AxiosBaseQueryArgs {
  baseUrl: string;
}

interface AxiosQueryArgs {
  url: string;
  method: AxiosRequestConfig['method'];
  data?: AxiosRequestConfig['data'];
  headers?: AxiosRequestConfig['headers'];
  params?: AxiosRequestConfig['params'];
  responseType?: ResponseType;
}

const defaultBaseUrl = import.meta.env.VITE_PUBLIC_APP_API;
const x_api_key = import.meta.env.VITE_X_API_KEY;

const axiosBaseQuery = (
  { baseUrl }: AxiosBaseQueryArgs = { baseUrl: defaultBaseUrl }
) => {
  return async ({
    url,
    method,
    data,
    params,
    headers,
    responseType,
  }: AxiosQueryArgs): Promise<{
    data?: unknown;
    error?: { status?: number; data?: unknown };
  }> => {
    const config: AxiosRequestConfig = {
      url: `${baseUrl}${url}`,
      method,
      data,
      params,
      responseType,
      headers: {
        ...headers,
        'x-api-key': x_api_key,
      },
    };

    try {
      const { data }: AxiosResponse = await axios(config);
      return { data };
    } catch (error) {
      const axiosError = error as AxiosError;
      return {
        error: {
          status: axiosError.response?.status,
          data: axiosError.response?.data || axiosError.message,
        },
      };
    }
  };
};

export default axiosBaseQuery;
