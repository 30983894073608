import { Link, useNavigate } from 'react-router-dom';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Hub = () => {
  const [isHovered, setIsHovered] = useState(false);

  const gifFile = [
    {
      id: 'a630ede4-8135-40dc-95a7-033045aa5a21',
      name: 'Ministry of innovation and technology',
      description:
        'COD-ICT is a comprehensive platform designed to enhance the visibility and growth of tech businesses.',
      url: '/gif/mint.gif',
      image: '/img/mint.png',
      link: 'https://mint-portal.vercel.app/',
      price: 2000,
      typeLanguage: ['Next JS', 'Tailwind', 'dasyUI'],
      producedBy: 'Kachamale Software Solutions',
    },
    {
      id: 'a630ede4-8135-40dc-95a7-033045aa5a22',
      name: 'Click Africa',
      description:
        'We are a premier transportation and logistics company with nearly three years of experience in the industry.',
      url: '/gif/We-are-live2.gif',
      image: '/img/click Africa.png',
      link: 'https://click-africa-website.vercel.app/',
      price: 2000,
      typeLanguage: ['Next JS', 'Tailwind', 'dasyUI'],
      producedBy: 'Kachamale Software Solutions',
    },
    {
      id: 'a630ede4-8135-40dc-95a7-033045aa5a23',
      name: 'Exeledes',
      description:
        'EKSLEDS Trading and Transport Share Company, established as a trade institute in 2022 G.C, has a deep-rooted foundation in the collaborative efforts of three pioneer transport associations in Ethiopia.',
      url: '/gif/exeledes.gif',
      image: '/img/exeledes.png',
      link: 'https://ekseledes-website-j04m.onrender.com/en',
      price: 2000,
      typeLanguage: ['Next JS', 'Mantine', 'Tailwind'],
      producedBy: 'Kachamale Software Solutions',
    },
  ];

  const navigate = useNavigate();
  const pathName = window.location.pathname;
  const { t } = useTranslation();

  const handleClick = (gif) => {
    navigate(`/hub/${gif.id}`, {
      state: {
        gifUrl: gif.url,
        gifLink: gif.link,
        price: gif.price,
        producedBy: gif.producedBy,
        typeLanguage: gif.typeLanguage,
        name: gif.name,
        description: gif.description,
      },
    });
  };

  return (
    <>
      {pathName === '/portfolio' && (
        <div>
          <Nav />
          <div className='container-fluid page-header py-5'>
            <div className='container text-center py-5'>
              <h1 className='display-2 text-white mb-4 animated slideInDown'>
                {t(`Portfolio`)}
              </h1>
              <nav aria-label='breadcrumb animated slideInDown'>
                <ol className='breadcrumb justify-content-center mb-0'>
                  <li className='breadcrumb-item'>
                    <Link to='/'>{t(`Home`)}</Link>
                  </li>
                  <li className='breadcrumb-item'>
                    <Link to='/hub'>
                      {t(`Pages`, {
                        ns: 'components_header_trn',
                      })}
                    </Link>
                  </li>
                  <li className='breadcrumb-item active' aria-current='page'>
                    {t(`Portfolio`)}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      )}
      <div className='container-fluid services py-4 mb-4'>
        <div
          className='text-center mx-auto pb-5 wow fadeIn'
          data-wow-delay='.3s'
        >
          <h5 className='txt-primary'>
            {t(`Websites and Web Design`, { ns: 'components_header_trn' })}
          </h5>
          <h1>
            {t(`Browse Our Website Show Cases`, {
              ns: 'components_header_trn',
            })}
          </h1>
        </div>
        <div className=''>
          <Suspense fallback={<div>Loading...</div>}>
            <div className='wow fadeIn'>
              <div className='row justify-content-center align-items-center g-4'>
                {gifFile.map((gif) => (
                  <div
                    className='col-lg-4 col-md-6 col-12 mb-4 wow fadeIn gif-container'
                    data-wow-delay='.3s'
                    key={gif.id}
                    onMouseEnter={() => setIsHovered(gif.id)}
                    onMouseLeave={() => setIsHovered(null)}
                  >
                    <div
                      key={gif.id}
                      className='card h-100'
                      style={{ borderRadius: '10px' }}
                    >
                      <figure className='mb-0'>
                        <img
                          src={isHovered === gif.id ? gif?.url : gif.image}
                          alt='arrow'
                          className='img-fluid'
                          style={{ width: '100%', height: 'auto' }}
                        />
                      </figure>
                      <div className='card-body d-flex flex-column justify-content-between'>
                        <div className='d-flex justify-content-center align-items-center'>
                          <button
                            onClick={() => handleClick(gif)}
                            className='italic txt-primary btn btn-sm outline-none hover-underline mb-3'
                            style={{ textDecoration: 'none', fontSize: '14px' }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.textDecoration =
                                'underline #0078d7 2px solid';
                              e.currentTarget.style.fontSize = '18px';
                            }}
                            onMouseLeave={(e) =>
                              (e.currentTarget.style.textDecoration = 'none')
                            }
                          >
                            {gif.name}
                          </button>
                        </div>
                        <div>
                          <p className='txt-sm text-start'>
                            By: {gif.producedBy}
                          </p>
                          <div className='d-flex justify-content-between gap-3'>
                            <div className=''>
                              <p className='txt-sm text-start'>
                                {gif.typeLanguage.join(', ')}
                              </p>
                            </div>
                            <div>
                              <button
                                className={`btn btn-outline-primary btn-sm`}
                                style={{
                                  transition: 'all 0.3s',
                                  width: '100%',
                                }}
                                onClick={() => handleClick(gif)}
                              >
                                {t(`Live Preview`, {
                                  ns: 'components_header_trn',
                                })}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Suspense>
        </div>
      </div>
      {pathName === '/portfolio' && <Footer />}
    </>
  );
};

export default Hub;
