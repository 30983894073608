import { useTranslation } from 'react-i18next';
import useAxiosQuery from '../baseQueryHook/useAxiosBaseQuery';

const WhyUs = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const collection = {
    locale: currentLanguage,
  };

  const { data: whyUsData } = useAxiosQuery({
    url: '/portal-interactions/get-why-us',
    method: 'GET',
    params: collection,
  });

  return (
    <>
      <div className='container-fluid services py-4 mb-4'>
        <div className='container'>
          <div
            className='text-center mx-auto pb-4 wow fadeIn'
            data-wow-delay='.3s'
            style={{ maxWidth: '600px' }}
          >
            <h5 className='txt-primary mb-4'>
              {t(`Why You Choose Kachamale Softwer Solution?`, {
                ns: 'components_header_trn',
              })}
            </h5>
          </div>

          <div className='row g-5'>
            {whyUsData?.data?.map((data) => (
              <div className='col-md-4 col-lg-4 g-5' key={data?.id}>
                <div className='d-flex'>
                  <div>
                    <i className='fas fa-bullet text-primary me-2'></i>
                  </div>
                  <div>
                    <h4>{data?.title}</h4>
                  </div>
                </div>
                <p className='indent-8 text-start text-wrap w-10'>
                  {data?.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyUs;
