import Footer from '../components/Footer';
import Nav from '../components/Nav';
import SingleProduct from '../components/SingleProduct';

import { useLocation } from 'react-router-dom';
import Slider from 'react-slick/lib/slider';
import useAxiosQuery from '../baseQueryHook/useAxiosBaseQuery';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: '#0078d7',
        borderRadius: '50%',
        color: 'green',
      }}
      onClick={onClick}
    />
  );
}

SampleNextArrow.propTypes = {
  className: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: '#0078d7',
        borderRadius: '50%',
        color: 'green',
      }}
      onClick={onClick}
    />
  );
}

SamplePrevArrow.propTypes = {
  className: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

const Project = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const collection = {
    locale: currentLanguage,
  };

  const { data: productList } = useAxiosQuery({
    url: '/portal-product-and-services/get-products',
    method: 'GET',
    params: collection,
  });

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 800,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    cssEase: 'linear',
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {currentPath === '/projects' ? (
        <>
          <Nav />
          {/* <!-- Page Header Start --> */}
          <div className='container-fluid page-header py-5'>
            <div className='container text-center py-5'>
              <h1 className='display-2 text-white mb-4 animated slideInDown'>
                {t(`Projects`)}
              </h1>
              <nav aria-label='breadcrumb animated slideInDown'>
                <ol className='breadcrumb justify-content-center mb-0'>
                  <li className='breadcrumb-item'>
                    <a href='#'>{t(`Home`)}</a>
                  </li>
                  <li className='breadcrumb-item'>
                    <a href='#'>
                      {t(`Pages`, {
                        ns: 'components_header_trn',
                      })}
                    </a>
                  </li>
                  <li className='breadcrumb-item' aria-current='page'>
                    {t(`Projects`)}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {/* <!-- Page Header End --> */}
        </>
      ) : null}

      {/* <!-- Project Start --> */}
      <div className='container-fluid products py-3 mb-4'>
        <div className='container'>
          <div
            className='text-center mx-auto pb-5 wow fadeIn'
            data-wow-delay='.3s'
            style={{ maxWidth: '700px' }}
          >
            <h5 className='txt-primary'>
              {t(`Our Project`, {
                ns: 'components_header_trn',
              })}
            </h5>
            <h1>
              {t(`Our Recently Completed Projects`, {
                ns: 'components_header_trn',
              })}
            </h1>
          </div>
          <div
            className='slider-container products-carousel wow fadeIn'
            data-wow-delay='.5s'
          >
            <Slider {...settings}>
              {productList?.data?.map((product, index) => (
                <SingleProduct key={index} product={product} />
              ))}
            </Slider>
          </div>
        </div>
      </div>
      {/* <!-- Project End --> */}
      {currentPath === '/projects' ? <Footer /> : null}
    </>
  );
};

export default Project;
