import { Link } from 'react-router-dom';

import useAxiosQuery from '../baseQueryHook/useAxiosBaseQuery';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

const HeroSlide = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const collection = {
    locale: currentLanguage,
  };
  const { data: frontImages, loading } = useAxiosQuery({
    url: '/portal-interactions/get-front-images',
    method: 'GET',
    params: collection,
  });

  if (loading) {
    return (
      <>
        <Suspense
          fallback={
            <div
              style={{
                height: '100vh',
                width: '100vw',
                backgroundColor: 'yellow',
              }}
              height={500}
            >
              {' '}
              <p>Loading ----</p>
              {/* <h1>hello</h1>{' '} */}
            </div>
          }
        >
          <Skeleton height={500} />
        </Suspense>
      </>
    );
  }

  const imageData = frontImages?.data?.map((image) => ({
    src: image.cover,
    alt: image.title,
    captionText: image.title,
    captionTitle: image.description,
    captionbottom: image.link,
  }));

  return (
    <div className='animated  fadeInUp' style={{ animationDuration: '1.5s' }}>
      <div className='container-fluid px-0'>
        <div id='carouselId' className='carousel slide' data-bs-ride='carousel'>
          <ol className='carousel-indicators'>
            {imageData?.map((image, index) => (
              <li
                key={index}
                data-bs-target='#carouselId'
                data-bs-slide-to={index}
                className={index === 0 ? 'active' : ''}
                aria-current={index === 0 ? 'true' : undefined}
                aria-label={`Slide ${index + 1}`}
              ></li>
            ))}
          </ol>
          <div className='carousel-inner' role='listbox'>
            {imageData?.map((image, index) => (
              <div
                key={index}
                className={`carousel-item ${index === 0 ? 'active' : ''}`}
              >
                <img
                  src={image.src}
                  className='img-fluid d-block w-100'
                  alt={image.alt}
                />
                <div className='carousel-caption'>
                  <div className='container carousel-content'>
                    <h6 className='txt-primary h4 animated fadeInUp'>
                      {image.captionTitle}
                    </h6>
                    <h1 className='text-white display-1 mb-4 animated fadeInRight'>
                      {image.captionText}
                    </h1>
                    <p className='mb-4 text-white fs-5 animated fadeInDown'>
                      {image.captionbottom}
                    </p>
                    <div className='d-flex justify-content-center'>
                      <Link to='/' className='ms-2'>
                        <button
                          type='button'
                          className='px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn1 animated fadeInLeft'
                        >
                          {t(`Read More`, { ns: 'components_header_trn' })}
                        </button>
                      </Link>
                      <Link to='/contact' className='ms-2'>
                        <button
                          type='button'
                          className='px-4 py-sm-3 px-sm-5 btn btn-primary rounded-pill carousel-content-btn2 animated fadeInRight'
                        >
                          {t(`Contact Us`, { ns: 'components_header_trn' })}
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <button
            className='carousel-control-prev'
            type='button'
            data-bs-target='#carouselId'
            data-bs-slide='prev'
          >
            <span
              className='carousel-control-prev-icon'
              aria-hidden='true'
            ></span>
            <span className='visually-hidden'>Previous</span>
          </button>
          <button
            className='carousel-control-next'
            type='button'
            data-bs-target='#carouselId'
            data-bs-slide='next'
          >
            <span
              className='carousel-control-next-icon'
              aria-hidden='true'
            ></span>
            <span className='visually-hidden'>Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeroSlide;
