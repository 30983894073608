import i18next from 'i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// const apiKey = '9sJcAO3DJ1ZGU7O5Z6SBmw';
// const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;

const loadPath = '/locales/{{lng}}/{{ns}}.json';

i18next
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',

    ns: ['navbar_translation', 'components_header_trn', 'about_des_trn'],
    defaultNS: 'navbar_translation',

    supportedLngs: ['en', 'am', 'om', 'so', 'ti'],

    backend: {
      loadPath: loadPath,
    },
  });
