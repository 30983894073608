import PropTypes from 'prop-types';

Avatar.propTypes = {
  Name: PropTypes.string.isRequired,
};

function Avatar({ Name }) {
  const getFirstLetter = (name) => {
    if (!name) return '';
    return name.charAt(0).toUpperCase();
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return (
    <>
      <div
        className='avatar-placeholder'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '80px',
          height: '80px',
          borderRadius: '50%',
          color: 'white',
          backgroundColor: getRandomColor(),
          fontSize: '40px',
        }}
      >
        {getFirstLetter(Name)}
      </div>
    </>
  );
}

export default Avatar;
