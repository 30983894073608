import axios from 'axios';
import React, { useState } from 'react';

const Modal = ({ isOpen, onClose, title, style, children }) => {
  if (!isOpen) return null;

  return (
    <div
      className='modal d-block '
      tabIndex={-1}
      role='dialog'
      style={{ borderRadius: '30px' }}
    >
      <div
        className='modal-dialog'
        role='document'
        style={{ ...style, width: style?.width, maxWidth: style?.maxWidth }}
      >
        <div
          className='modal-content '
          style={{ borderRadius: style?.borderRadius }}
        >
          <div className='modal-header'>
            <h5 className='modal-title text-center justify-content-center'>
              {title}
            </h5>
            <button
              type='button'
              className='btn-close'
              onClick={onClose}
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body'>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
