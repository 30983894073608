import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const SingleProduct = ({ product }) => {
  const { t } = useTranslation();
  return (
    <div
      className=' border  p-0'
      style={{ height: '550px', borderRadius: '10px' }}
    >
      <div className='d-flex align-items-center'>
        <div className='border-circle' style={{ height: '550px' }}>
          {product?.coverPage && (
            <img
              style={{
                height: '220px',
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
              }}
              src={product?.coverPage}
              className='img-fluid w-100'
              alt={product.name}
            />
          )}
          <div className='p-4 text-center products-content'>
            {/* <div className="products-content-icon"> */}
            {/* <i className="fa fa-code fa-7x mb-4 txt-primary"></i> */}

            <h4 className='mb-3'>{product.name}</h4>
            <p className='mb-4'>
              {' '}
              {product?.description?.length > 235
                ? product?.description?.slice(0, 235) + ' ...'
                : product?.description}
            </p>
            <div className=''>
              <Link
                to={`/projects/${product.id}`}
                className='btn btn-primary text-white px-5 py-3 '
              >
                {t(`Read More`, { ns: 'components_header_trn' })}
              </Link>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
SingleProduct.propTypes = {
  product: PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string,
    category: PropTypes.string,
    coverPage: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
};
export default SingleProduct;
