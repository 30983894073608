// 'use client';
import { useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import { useTranslation } from 'react-i18next';
import useAxiosQuery from '../baseQueryHook/useAxiosBaseQuery';

export default function ProductDetail() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  // const collection = {
  //   locale: currentLanguage,
  // };

  const { data: productData } = useAxiosQuery({
    url: `/portal-product-and-services/get-product/${id}`,
    method: 'GET',
    // params: collection,
  });

  if (productData) {
    return (
      <>
        <Nav />
        <div className='container-fluid page-header py-5'>
          <div className='container text-center py-5'>
            <h1 className='display-2 text-white mb-4 animated slideInDown'>
              {productData?.name?.[currentLanguage]}
            </h1>
            <nav aria-label='breadcrumb animated slideInDown'>
              <ol className='breadcrumb justify-content-center mb-0'>
                <li className='breadcrumb-item'>
                  <a href='#'>{t(`Home`)}</a>
                </li>
                <li className='breadcrumb-item'>
                  <a href='#'>
                    {t(`Projects`, { ns: 'components_header_trn' })}
                  </a>
                </li>
                <li className='breadcrumb-item' aria-current='page'>
                  {t(`Product Detail`, { ns: 'components_header_trn' })}
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className='container-fluid py-3 my-3'>
          <div className='container py-3'>
            <div className='row g-5'>
              <div
                className='col-lg-5 col-md-6 col-sm-12 wow fadeIn'
                data-wow-delay='.3s'
              >
                <div className='h-100 position-relative'>
                  <img
                    src={`${productData?.coverPage}`}
                    className='img-fluid w-75 rounded'
                    alt=''
                    style={{ marginBottom: '25%' }}
                  />
                </div>
              </div>
              <div
                className='col-lg-7 col-md-6 col-sm-12 wow fadeIn'
                data-wow-delay='.5s'
              >
                <h1 className='mb-4'>
                  {t(`About`)} {productData?.name?.[currentLanguage]}
                </h1>
                <div>{productData?.description?.[currentLanguage]}</div>
                <div
                  className='mt-4'
                  dangerouslySetInnerHTML={{
                    __html: productData?.content?.[currentLanguage],
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}
